  .linkButton {
    transition: color 0.3s ease;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 200;
    cursor: pointer;
  }

  .header-bg{
    background-color: #080808;
    position: fixed;
    top: 0;
    margin-top: 0;
    padding: 8px;
  }

  .footerLink{
    cursor: pointer;
  }

  .footerLink:hover {
    color: #FFA000;
  }

  .linkButton:hover,
  .linkButton.selected:hover {
    color: #FFA000;
    font-weight: 400;
  }
  
.LinkListContainer {
  background-color: #080808;
  padding: 0.4rem; 
  border-radius: 4px; 
  width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.expand-on-hover-btn {
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease; 
}

.expand-on-hover-btn:hover {
  transform: scale(1.1); 
}

.button-text {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
  transition: color 0.3s ease;
}

.expand-on-hover-btn:hover .button-text {
  color: #ffc107; 
}

.contact-section {
  padding: 1rem;
}

.contact-section .footerLink {
  font-size: 14px;
}

.contact-section .contact-details {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .hide-button-mobile-view {
    display: none;
  }
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-btn.show {
  opacity: 1;
}