

@media only screen and (max-width: 2480px) {

    .bootcamp_heading{
        font-size: 73px;
    }
    .header_text{
        padding-top: 20px;
    }
  }

  @media only screen and (max-width: 1300px) {
    .bootcamp_heading{
        font-size: 53px;
        text-align: center;
    }
    .bootcamp_subhead{
        font-size: 18px; 
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .bootcamp_heading{
        font-size: 53px;
        text-align: center;
    }
    .bootcamp_subhead{
        font-size: 28px; 
    }
}
  
  @media only screen and (max-width: 700px) {
    .bootcamp_heading{
        font-size: 30px;
        text-align: center;
    }
    .bootcamp_subhead{
        font-size: 18px; 
    }
  }
  @media only screen and (max-width: 650px) {
    .bootcamp_heading{
        font-size: 30px;
        text-align: center;
    }
    .bootcamp_subhead{
        font-size: 18px; 
    }
  }
  @media only screen and (max-width: 560px) {

  }
  
  @media only screen and (max-width: 460px) {
    .bootcamp_heading{
        font-size: 30px;
        text-align: center;
    }
    .bootcamp_subhead{
        font-size: 18px; 
    }
  }

  