.home_cards_container {
  width: 100%;
}

.home_cards_container .container {
  gap: 50px;
}

.home_card {
  padding: 32px;
  background: linear-gradient(
    183deg,
    rgba(65, 105, 225, 0.15) -38.62%,
    rgba(65, 105, 225, 0) 97.56%
  );
  border: 0.5px solid var(--primary);
  max-width: 550px;
  border-radius: 8px;
  margin: 0 auto;
}

.card_image {
  margin-bottom: 40px;
  max-width: 100%;
  min-height: 300px;
  height: 350px;
  margin: 0 auto;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_card h3 {
  font-size: 30px;
  font-weight: 600;
  padding: 40px 0 24px;
}

.home_card p,
.home_card ul li {
  font-size: 18px;
  font-weight: 500;
}

.home_card p {
  padding-bottom: 48px;
}
.home_card ul {
  list-style-type: disc;
  line-height: 30px;
  letter-spacing: 0.09px;
  margin-left: 30px;
}

.home_card h4 {
  font-size: 18px;
  padding: 72px 0 48px;
}

.home_card a {
  display: block;
  margin-top: 24px;
}

@media only screen and (max-width: 970px) {
  .home_card h4 {
    font-size: 16px;
    padding: 50px 0 24px;
  }

  .home_card p,
  .home_card ul li {
    font-size: 16px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 560px) {
  .home_card {
    padding: 15px;
  }
  .home_card h3 {
    font-size: 24px;
    padding: 24px 0 20px;
  }
}
@media only screen and (max-width: 465px) {

  .home_card h3 {
    font-size: 18px;
    padding: 24px 0 20px;
  }
}
