.section_wrapper{
    width: 100%;
}
.section_bg{
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.346) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    top / cover no-repeat url(/public/images/home-bg.png); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.section_bg .container{
    flex-direction: row-reverse;
}
.section_bg{
    text-align: right;
}
.section_wrapper h3{
    font-size: 60px;
    padding-bottom: 24px;
    font-weight: 600;
}

@media only screen and (max-width: 970px){
    .section_wrapper{
        padding: 80px 0 30px;
    
    }
    
    .section_wrapper h3{
        font-size: 36px;
    }

    .section_bg .container{
        flex-direction: column;
    }
}

@media only screen and (max-width: 425px){
    .section_wrapper{
        padding: 20px 0 30px;
    }
    .section_wrapper h3{
        font-size: 24px;
    }
}