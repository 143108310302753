.cat_btn{
    background-color: transparent;
}
.cat_btn:hover{
    border-bottom: 2px solid #fff;
}
/* .cat_btn.active{
    border-bottom: 2px solid #fff;

/* } */
.categories_wrapper{
    width: 250px;
    flex-direction: row;
    
}
@media screen and (max-width: 560px){
    .categories_wrapper{
        align-self: flex-start;
    }
}