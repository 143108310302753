.slide_container {
  /* height: 350px; */
  border-radius: 5px;
}

.vertical_item {
  position: relative;
  max-width: 100%;
  height: 100%;
}

.vertical_item span {
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.vertical_item span .icon {
  font-size: 30px;
}

.slide_content {
  gap: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  transition: all 0.3s ease;
  padding: 40px 20px 20px;
  margin-top: 10px;
}

.slide_content h3 {
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 20px;
}

.slide_content p {
  font-size: 16px;
  font-weight: 100;
}
/* style for active state for the vertical slide */
.vertical_item.slick-active .slide_content {
  opacity: 1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    173deg,
    rgba(255, 255, 255, 0.4) -112.8%,
    rgba(65, 105, 225, 0) 66.69%
  );
}
.vertical_item.slick-active .icon {
  color: var(--primary);
}
.vertical_item.slick-active span {
  border: 0.5px solid var(--primary);
}

@media only screen and (max-width: 460px) {
  .slide_content h3 {
    font-size: 20px;
  }

  .slide_content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 390px) {
  .slide_content {
    gap: 10px;
    padding: 35px 15px 15px;
  }
  .slide_content h3 {
    font-size: 18px;
  }

  .vertical_item span .icon {
    font-size: 24px;
  }
}
