.main_container{
  height: 150px;
  margin-top: 150px;
  background: linear-gradient(173deg, rgba(255, 255, 255, 0.40) -112.8%, rgba(65, 105, 225, 0.00) 66.69%);
  -webkit-mask:
   radial-gradient(60% 30% at top    ,transparent 98%, #fff) top   /100% 50% no-repeat,
   radial-gradient(60% 30% at bottom ,transparent 98%, #fff) bottom/100% 50% no-repeat;
  mask:
   radial-gradient(60% 30% at top    ,transparent 98%, #fff) top   /100% 50% no-repeat,
   radial-gradient(60% 30% at bottom ,transparent 98%, #fff) bottom/100% 50% no-repeat;
  display: flex;
  align-items: center;
   

}
.brand_carousel_container {
  width: 80%;
  margin: 80px auto; 
}

.brand_item {
  padding: 0 20px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand_item img {
  text-align: center;
  width: 150px;
  height: 100%;
}

/* to remove the outline when an image on the slider is clicked */
.slick-slide div {
  outline: none;
}

@media only screen and (max-width: 1025px){
    .brand_carousel_container {
        width: 95%;
    }
}