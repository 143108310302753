/* TestimonialCarousel.css */
.testimonial-carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 20px 0px;
}

.sidebar {
  width: 40%;
  border-left: 1px solid #fff;
}
.thumbnail-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  float: right;
}

.carousel {
  width: 60%;
  padding-right: 50px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.5s ease;
  object-fit: cover;
}

.thumbnail.active {
  opacity: 1;
  transform: scale(1.2);
}

.testimonial-item {
  padding: 20px 10px 20px 10px;
}
.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonial-text.active {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
}

.author.active {
  font-style: italic;
}

@media only screen and (max-width: 970px) {
  .testimonial-image, .thumbnail {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 826px) {
  .testimonial-carousel-container{
    padding: 0;
  }
  .carousel {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 704px) {
  .testimonial-image{
    margin: 20px auto;
  }
  .testimonial-carousel-container {
    flex-direction: column;
    height: 720px;

  }
  .sidebar {
    width: 70%;
    
  }
  .carousel {
    width: 100%;
    padding-right: 0;
  }
}
@media only screen and (max-width: 570px) {
  .thumbnail{
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
 
  .testimonial-carousel-container{
    padding: 0;
  }
  .sidebar {
    width: 80%;
    margin: 0 auto;
    
  }
  .testimonial-item {
    padding: 20px 10px;
  }
  .testimonial-text.active {
    text-align: left;
    font-size: 14px;
  }
  .testimonial-carousel-container {
    height: 750px;
    justify-content: center;
    gap:25px;
  }

 
}

@media only screen and (max-width: 365px){
  .thumbnail{
    width: 40px;
    height: 40px;
  }

}

