.blog_list {
  width: 100%;
  padding: 150px 80px 0;
}
.featured_post {
  gap: 30px;
}
.featured_post h5 {
  font-size: 18px;
  font-weight: 400;
}
.featured_post h1 {
  font-size: 60px;
  font-weight: 600;
  padding-bottom: 24px;
  line-height: normal;
}
.featured_post p {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 0;
  letter-spacing: 0.09px;
}
.featured_post btn {
  margin-top: 24px;
}
.featured_post .img_wrap {
  max-width: 500px;
  margin: 0 auto;
}
.featured_post.img_wrap img {
  mix-blend-mode: luminosity;
  width: 100%;
}
.mini_nav {
  height: 100px;
}
.recent_blogs {
  padding: 100px 0 170px;
}
.tab_blog_grid {
  display: none;
}
.newsletter_wrapper {
  padding: 149px 0;
}
.newsletter {
  background: #111;
  padding: 20px 30px;
  width: 100%;
}
.newsletter_form_col {
  flex-basis: 55%;
  height: 100%;
}
.newsletter_form_col h5 {
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 24px;
}
.newsletter_form_col form input {
  margin: 10px 30px 24px 0;
  width: 90%;
  padding: 12px 16px;
}
.newsletter_form_col form label {
  font-size: 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.newsletter_form_col form button {
  margin: 0;
}
.newsletter_form_col form input::placeholder {
  color: #efc0fc;
  font-size: 14px;
  font-weight: 300;
}
.newsletter_img_col {
  flex-basis: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.newsletter_img_col div {
  width: 300px;
  height: 320px;
}
.newsletter_img_col div img {
  width: 100%;
  height: 100%;
}

/* styling for single blog page */
.blog {
  padding: 150px 100px;
}
.blog_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.blog_container h1 {
  font-size: 48px;
  flex-basis: 55%;
}
.blog_container .blog_img_wrapper {
  flex-basis: 45%;
}
.blog_container .blog_img_wrapper img {
  width: 100%;
}
.blog_content {
  padding-bottom: 46px;
}
.blog_content .intro {
  padding-top: 37px;
  font-size: 18px;
}
.subtopic h2 {
  font-size: 30px;
  font-weight: 500;
  padding: 32px 0;
}
.subtopic p {
  font-size: 18px;
}
.share_container {
  padding: 100px 189px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(17.5px);
}
.share_container h3 {
  text-align: center;
  margin-bottom: 32px;
  font-size: 30px;
  font-weight: 700;
}
.share_button button {
  padding: 12px 24px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 286px;
}
@media only screen and (max-width: 1200px){
  .share_container{
    padding: 100px 75px;
  }
}
@media only screen and (max-width: 970px) {
  .blog_list {
    padding: 150px 32px 0;
  }
  .featured_post {
    flex-direction: row;
  }
  .featured_post h1 {
    font-size: 48px;
  }
  .featured_post .text_col {
    text-align: left;
  }
  .mini_nav {
    height: 100px;
    flex-direction: row;
  }
  .recent_blogs {
    padding: 50px 0 100px;
  }
  .tab_blog_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: space-between;
  }
  .desktop_blog_slider {
    display: none;
  }
  .mini_nav {
    padding-top: 80px;
  }
  .recent_blogs {
    padding-bottom: 80px;
  }
  .newsletter {
    flex-direction: row;
  }
  .newsletter_form_col h5 {
    font-size: 24px;
  }
  /* blog styling */

  .blog_content .intro {
    padding-top: 54px;
    font-size: 16px;
  }
  .share_container {
    padding: 96px 65px;
  }
  .share_container h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 46px;
  }
  .subtopic h2 {
    font-size: 24px;
  }
  .subtopic p {
    font-size: 16px;
  }
  .blog {
    padding: 137px 32px;
  }
  .blog_container {
    padding: 27px 0;
  }
  .blog_container h1 {
    font-size: 36px;
    flex-basis: 50%;
  }
  .blog_container .blog_img_wrapper {
    flex-basis: 50%;
  }

  .share_button{
    flex-direction: row;
    padding: 0 14px;
  }
}
@media only screen and (max-width: 834px) {
  .tab_blog_grid {
    grid-template-columns: 1fr;
    gap: 100px;
  }
  .newsletter {
    flex-direction: column;
    text-align: center;
    padding: 68px 30px;
  }
  .newsletter_form_col form input {
    margin: 10px 0px 24px;
    width: 100%;
  }
  .newsletter_form_col form button {
    width: 100%;
  }
  .newsletter_form_col form label {
    float: left;
  }
  /* Blog style */
  .share_button button{
    width: 200px;
  }
}
@media only screen and (max-width: 650px) {
  .featured_post {
    flex-direction: column-reverse;
  }

  .featured_post h5 {
    font-size: 12px;
    padding-bottom: 15px;
  }
  .featured_post h1 {
    font-size: 30px;
  }
  .featured_post p {
    font-size: 16px;
  }
  .featured_post .img_col {
    width: 100%;
  }
  .featured_post .img_wrap {
    max-width: 100%;
    margin: 0 auto 30px;
  }
  .featured_post .img_wrap img {
    width: 100%;
  }
  /* Blog style */
  .share_container {
    padding: 96px 22px;
  }
  .blog_container{
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 560px) {
  .blog_list {
    padding: 150px 15px 0;
  }
  .mini_nav {
    flex-direction: column;
    height: 80px;
    padding-top: 0;
    margin-top: 100px;
  }
  .recent_blogs {
    padding-bottom: 100px;
  }
  .mini_nav h4 {
    align-self: flex-start;
  }
  .featured_btn {
    margin-bottom: 0;
  }
  .newsletter {
    padding: 68px 10px;
  }
  .newsletter_wrapper {
    padding-top: 0;
  }
    /* Blog style */
  .blog_container h1 {
    font-size: 30px;
  }
  .share_button{
    flex-direction: column;
    gap: 30px;
  }
  .subtopic h2 {
    font-size: 20px;
  }
}
